<template>
    <div class="inner_pages" id="checkout_clients">
        <sidebar-component v-model="showSidebar"/>
        <div class="container">
            <div class="respective_content">
                <div class="acton_header m-0">
                    <button type="button" class="bar_menu" @click="showSidebar = !showSidebar"><img src="@/assets/images/bar_menu.svg"></button>
                    <h2>Logs</h2>
                    <li class="search_area">
                        <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="params.search"/>
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </li>
                    <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button> -->
                </div>
                <ul class="tabs">
                    <li :class="{'active' : logTabs === 'completed'}" @click="logTabs = 'completed'">Successful Charges</li>
                    <li :class="{'active' : logTabs === 'single'}" @click="logTabs = 'single'">Failed Single Charges</li>
                    <li :class="{'active' : logTabs === 'recurring'}" @click="logTabs = 'recurring'">Failed Recurring</li>
                    <li :class="{'active' : logTabs === 'abandoned'}" @click="logTabs = 'abandoned'">Abandoned Cart</li>
                    <li :class="{'active' : logTabs === 'refunded'}" @click="logTabs = 'refunded'">Refunds</li>
                    <li :class="{'active' : logTabs === 'coupon'}" @click="logTabs = 'coupon'">Coupon</li>
                </ul>
                <div class="result_wpr new">
                    <div class="actions mt-4">
                        <ul class="left">
                            <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                                <label>Tutorials</label><img src="@/assets/images/play.svg">
                                <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                    <ul>
                                        <li @click="tutorial = true;">How to use the logs feature</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <ul class="right">
                            <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                                <input type="text" placeholder="Search..." @input="isTyping = true" v-model.trim="params.search"/>
                                <button class="search_btn" @click="searchField = !searchField;">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li> -->
                            <li class="optionDrops contacts-tabs" @click="orderFilter = !orderFilter" v-if="params.status === 'completed'" v-click-outside="closeOrderFilter">
                                <label>{{ Helper.capitalizeFirst(params.order_type) }}</label><i class="fas fa-angle-down"></i>
                                <div class="dropdown_wpr" :class="orderFilter ? 'active' : ''">
                                    <ul>
                                        <li @click="handleOrderType('all')">All</li>
                                        <li @click="handleOrderType('single')">Single</li>
                                        <li @click="handleOrderType('recurring')">Recurring</li>
                                    </ul>
                                </div>
                            </li>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="1" :is-dropdown="true" ref="logs-per-page-filter" />
                            </li>
                        </ul>
                    </div>
                    <div class="contact_loader" v-if=" checkoutLogsLoader"><quote-loader /></div>
                    <div class="scroll_table">
                        <table class="show_header" v-show="!checkoutLogsLoader">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th align="left">Name</th>
                                    <th>Email</th>
                                    <th>Product</th>
                                    <th>Date</th>
                                    <template v-if="logTabs !== 'coupon'">
                                        <th>Price</th>
                                        <th v-if="logTabs === 'recurring'">Dunning Stage</th>
                                        <th v-if="logTabs === 'abandoned'">Cart Recovery Stage</th>
                                        <th style="min-width: 110px;">Status</th>
                                        <th v-if="logTabs === 'single'">Failed Reason</th>
                                        <th v-if="logTabs === 'refunded'">Refund Reason</th>
                                        <th v-if="logTabs === 'refunded'">Refund Amount</th>
                                        <!-- <th>Actions</th> -->
                                    </template>
                                    <template v-else>
                                        <!-- <th>Date</th> -->
                                        <th>Discount</th>
                                        <th>Code</th>
                                        <th style="min-width: 110px;">Status</th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody v-if="checkoutLogs.total">
                                <tr v-for="(log, l) in checkoutLogs.data" :key="l">
                                    <td><a @click="handleClientDetails(log.client)">{{ log ? (log.order_id ? log.order_id : log.id) : '-'}}</a></td>
                                    <td>
                                        <div class="user_wpr" @click="handleClientDetails(log.client)">
                                            <h4>{{ log && log.client ? log.client.full_name : '-'}}</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="email_wpr">
                                            <span class="email_add">{{ log && log.client ? log.client.email : '-'}}</span>
                                        </div>
                                    </td>
                                    <td>{{ log && log.product ? log.product.name : '-'}}</td>
                                    <td>{{ moment(log.created_at).format('ll | LT')}}</td>
                                    <template v-if="logTabs !== 'coupon'">
                                        <td>{{ companyCurrencySymbol }}{{ log && log.price ? parseFloat((parseFloat(log.price) - parseFloat(log.discount_amount))).toFixed(2) : '-'}}</td>
                                        <td v-if="logTabs === 'recurring'">Default</td>
                                        <td class="reason w-150" v-if="logTabs === 'abandoned'">
                                            {{ log.abandoned_email }}
                                        </td>
                                        <td v-if="logTabs === 'completed'"><label>Completed</label></td>
                                        <td v-else-if="logTabs === 'abandoned'">
                                            <label :class="`abandoned-${log.abandoned_status.toLowerCase().replaceAll(' ', '-')}`">{{ log.abandoned_status }}</label>
                                        </td>
                                        <td v-else><label>{{ log.status == 'pending' ? 'In process' : Helper.capitalizeFirst(log.status) }}</label></td>
                                        <!-- <td>{{ moment(log.created_at).format('ll | LT')}}</td> -->
                                        <td class="reason" v-if="logTabs === 'single' || logTabs === 'refunded'"><p>{{ log.description ? log.description : '-' }}</p></td>
                                        <td v-if="logTabs === 'refunded'">{{ companyCurrencySymbol }}{{ parseFloat(log.price).toFixed(2) }}</td>
                                        <!-- <td>
                                            <ul class="action_list mr-3">
                                                <li title="Send Invoice" @click="handleSendInvoice(log)" v-if="logTabs !== 'abandoned'">
                                                    <i :class="{ 'fas fa-paper-plane': sendInvoiceLoader == 0 || sendInvoiceLoader != log.order_id, 'fa fa-spin fa-spinner': sendInvoiceLoader == log.order_id }"></i>
                                                </li>
                                            </ul>
                                        </td> -->
                                    </template>
                                    <template v-else>
                                        <td>{{ companyCurrencySymbol }}{{ log.order && log.order.discount ? parseFloat(log.order.discount).toFixed(2) : '-' }}</td>
                                        <td>{{ log.order && log.order.coupon && log.order.coupon.code ? log.order.coupon.code : '-' }}</td>
                                        <td><label>Redeemed</label></td>
                                    </template>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="8" class="px-4 text-center" style="border-radius: 0 0 10px 10px;">No data found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ checkoutLogs.from ? checkoutLogs.from : 0 }} - {{ checkoutLogs.to ? checkoutLogs.to : 0 }} of {{ checkoutLogs.total }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="checkoutLogs.total">
                    <pagination v-model="params.page" :pages="checkoutLogs.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
        </div>
        <profile-component v-model="details" :selected-client="selectedClient" />
        <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
        <!-- <refund-component v-model="refundPayment" /> -->
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    import moment from 'moment'
    import Helper from '@/utils/Helper'

    const VideoTutorial = defineAsyncComponent(() => import('@/components/VideoTutorial'))
    const ProfileComponent = defineAsyncComponent(() => import('@/pages/checkout/components/client/Profile'))
    const SidebarComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Sidebar'))
    const SubscriptionDetails = defineAsyncComponent(() => import('@/pages/checkout/components/SubscriptionDetails'))
    // const RefundComponent = defineAsyncComponent(() => import('@/pages/checkout/components/Refund'))

    export default {
        name: 'Checkout Logs',

        data () {
            return {
                logTabs: 'completed',
                details: false,
                orderFilter: false,
                refundPayment: false,
                tutorialList: false,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/820e58970ccc40e396c43b604e63184b?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                params: {
                    page: 1,
                    per_page: 6,
                    search: '',
                    status: 'completed',
                    order_type: 'all',
                },
                Helper,
                selectedClient: {},
                selectedOrder: {},
                isTyping: false,
                searchField: false,
                showSidebar: false,
                moment
            }
        },

        watch: {
            logTabs (type) {
                const vm = this;

                vm.params.page       = 1;
                vm.params.search     = '';

                if (type == 'completed') {
                    vm.params.status     = 'completed';
                    vm.params.order_type = 'all';
                } else if (type == 'single') {
                    vm.params.status     = 'failed';
                    vm.params.order_type = 'single';
                } else if (type == 'recurring') {
                    vm.params.status     = 'failed';
                    vm.params.order_type = 'recurring';
                } else if (type == 'abandoned') {
                    vm.params.status     = 'abandoned';
                    vm.params.order_type = 'all';
                } else if (type == 'refunded') {
                    vm.params.status     = 'refunded';
                    vm.params.order_type = 'all';
                } else if (type == 'coupon') {
                    vm.params.status     = 'coupon';
                    vm.params.order_type = 'all';
                }

                vm.getCheckoutLogs(vm.params);
            },

            'params.per_page' (page) {
                const vm = this;

                vm.params.page = 1;
                vm.getCheckoutLogs(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                vm.params.page = 1;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null && (vm.params.search.length >= 2 || vm.params.search.length == 0)) {
                        vm.getCheckoutLogs(vm.params);
                    }
                }
            },
        },

        components:{
            SidebarComponent,
            ProfileComponent,
            VideoTutorial
            // RefundComponent,
        },

        computed: mapState({
            checkoutLogs: state => state.checkoutModule.checkoutLogs,
            checkoutLogsLoader: state => state.checkoutModule.checkoutLogsLoader,
            companyCurrencySymbol: state => state.checkoutModule.companyCurrencySymbol,
            sendInvoiceLoader: state => state.checkoutModule.sendInvoiceLoader,
        }),

        mounted () {
            const vm = this;

            vm.getCheckoutLogs(vm.params);
        },

        methods: {
            ...mapActions({
                getCheckoutLogs: 'checkoutModule/getCheckoutLogs',
                sendSubscriptionInvoice: 'checkoutModule/sendSubscriptionInvoice',
                sendOrderInvoice: 'checkoutModule/sendOrderInvoice',
            }),

            togglePerPageFilter () {
                const vm     = this;
                const filter = vm.$refs['logs-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['logs-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getCheckoutLogs(vm.params);
            },

            handleOrderType (type) {
                const vm = this;

                vm.params.page       = 1;
                vm.params.search     = '';
                vm.params.order_type = type;
                vm.getCheckoutLogs(vm.params);
            },

            handleClientDetails (client) {
                const vm = this;

                vm.selectedClient = client;
                vm.details        = true;
            },

            handleSendInvoice (log) {
                const vm = this;

                if (log.order && log.order.has_subscription && !log.order.has_trial) {
                    vm.sendSubscriptionInvoice(log.order_id);
                } else if (log.order && (!log.order.has_subscription || (log.order.has_subscription && log.order.has_trial))) {
                    vm.sendOrderInvoice(log.order_id);
                }
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            closeOrderFilter(){
                const vm = this;

                vm.orderFilter = false;
            }
        }
    }
</script>

<style scoped>
    .bar_menu {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: 10px;
        margin-left: 0 !important;
        display: none;
    }
    .container{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
    }
    .video_player .modal_body {
        width: 100%;
        height: 400px;
        display: flex;
        padding: 15px;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .tabs{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 30px;
    }
    .tabs li{
        border: 0;
        background: #e9e9e9;
        padding: 10px 20px;
        border-radius: 3px;
        font-size: 13px;
        line-height: 19px;
        color: #5a5a5a;
        font-weight: 500;
        width: auto;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    .tabs li.active{
        background: #2f7eed;
        color: #fff;
    }
    .result_wpr.new{
        overflow: visible;
    }
    .result_wpr .actions > ul.right li.contacts-tabs .dropdown_wpr{
        left: auto;
        right: 1px;
    }
    .result_wpr table td:first-child, .result_wpr table th:first-child {
        width: 90px;
        padding: 10px 18px;
        text-align: left;
    }
    .result_wpr table td:last-child, .result_wpr table th:last-child {
        text-align: center;
    }
    .result_wpr table td a{
        color: #2f7eed;
        font-weight: 500;
        cursor: pointer;
    }
    .result_wpr table td label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        border: 1px solid #9FB1CF;
        border-radius: 11px;
        padding: 3px 10px;
    }
    .result_wpr table td.reason{
        width: 230px;
    }
    .result_wpr table td.reason p{
        font-size: 11px;
        line-height: 14px;
        font-weight: 300;
    }
    .result_wpr table td .action_list{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
    .result_wpr table td .action_list li i{
        color: #2f7eed;
        border-radius: 50%;
        font-size: 15px;
    }
    .actions > ul li.duration{
        position: relative;
    }
    .custom_range {
        text-align: left;
        background: #fff;
        position: absolute;
        top: 100%;
        right: 0;
        width: 200px;
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.15);
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        margin-top: 1px;
        display: none;
        opacity: 0;
    }
    .custom_range.active{
        animation: dropMove 0.5s ease-in-out;
        animation-timing-function: cubic-bezier(1.0, 0.5);
        display: block;
        opacity: 1;
    }
    .custom_range:before{
        content: '';
        position: absolute;
        right: 17px;
        top: -6px;
        width: 10px;
        height: 10px;
        border-top: 1px solid #E0E0E0;
        border-left: 1px solid #E0E0E0;
        background: #fff;
        transform: rotate(45deg);
    }
    .custom_range h4{
        padding: 10px 15px;
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        border-bottom: 1px solid #ececec;
    }
    .custom_range ul{
        display: flex;
        flex-direction: column;
        margin: 10px 0 15px;
        line-height: 0;
        gap: 12px;
    }
    .custom_range ul li{
        border: 0;
        padding: 5px 15px;
        height: auto;
        margin: 0;
        width: 100%;
        display: block;
    }
    .custom_range ul li label{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 3px;
        display: block;
    }
    .custom_range ul li :deep(input){
        width: 100%;
        border: 1px solid #cdcdcd;
        height: 30px;
        border-radius: 3px;
        padding: 0 5px 0 10px;
        color: #808080;
        font-size: 12px;
        line-height: 15px;
    }
    .custom_range .save_btn{
        padding: 7px 15px;
        background: #2f7eed;
        color: #fff;
        width: 100%;
        border-radius: 3px;
        cursor: pointer;
        font-size: 12px;
        line-height: 14px;
    }

    .result_wpr table td label.abandoned-expired {
        border-color: #eb1414;
        color: #eb1414;
    }

    .result_wpr table td label.abandoned-subscribed,
    .result_wpr table td label.abandoned-order-placed {
        border-color: #30C13E;
        color: #30C13E;
    }

    .w-150 {
        width: 150px;
        max-width: 150px;
    }

    @media(max-width: 1350px){
        .tabs li{
            padding: 7px 12px;
            font-size: 11px;
            line-height: 16px;
        }
    }
    @media(max-width: 767px){
        .acton_header .search_area input{
            width: 170px;
        }
    }
    @media(max-width: 1199px){
        .bar_menu {
            display: block;
        }
    }
    @media(max-width: 600px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .tabs{
            gap: 7px;
        }
        .tabs li{
            padding: 5px 7px;
        }
    }
</style>
